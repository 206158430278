import axios from "@/axios";
import router from "@/routes/router";
import { replaceUrlParam } from "@/util/replaceUrlParam";
import { apiHandler } from "@/util/errorHandling";
import { formatCurrency } from "../../../util/formatCurrency";
import { Message } from "element-ui";
import preloader from "@/util/preloader";
import {
  getValueFromLocalStorage,
  checkIfKeyExist,
} from "../../../util/localStorageHandler";
export default {
  async fetchChangePlanDate(context) {
    try {
      const res = await axios.get("payments/change-plan-date", {
        headers: { Authorization: `Bearer ${context.state.apiToken}` },
      });
      context.commit("setChangePlanDate", res.data.change_date);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchBillingInfo(context) {
    try {
      const res = await axios.get("/user/billing-info-data");
      context.commit("setBillingInfo", res.data);

      const hasBillingInfo = res.data.data === null ? false : true;

      context.commit("setHasBillingInfo", hasBillingInfo);
      return res;
    } catch (error) {
      console.error(error);
    }
  },
  async setupIntent({ commit }) {
    const res = await axios.get("payments/setup-intent");
    commit("setStripeClientSecret", res.data.client_secret);
  },
  async fetchDefaultPaymentMethod({ commit }) {
    const { data } = await axios.get("payments/pay-method");

    commit("setDefaultPaymentMethod", data);
  },
  async submitCoupon({ commit, getters, rootState, rootGetters }, { coupon }) {
    if (!coupon) return false;
    const user = rootGetters["getUser"];
    const currency = rootGetters["pricing/getUserCurrency"];
    const cart = rootGetters["cart/getCart"];
    const type = cart[0]?.plan_package ? "subscription" : "one-shot";

    const couponCheckUrl = user
      ? `/payments/check-coupon`
      : `features/fastcheckout/fast-checkout-coupon`;

    axios
      .post(couponCheckUrl, {
        code: coupon,
        type: type,
        user_id: user?.id,
        currency: currency.code,
        items: cart,
      })
      .then((response) => {
        console.log(response);
        const data = response.data.data;
        if (!data.valid) {
          console.log("data not valid");
          throw new Error("Coupon not valid");
        }
        commit("setDiscounts", data.coupon);
        commit("setCouponCode", coupon);
      })
      .catch((error) => {
        console.log(error);
        Message({
          showClose: true,
          message: "Coupon not valid",
          type: "error",
        });
        return false;
      });
  },
  async currencyCheckOnPlan({ commit, dispatch }, data) {
    await axios
      .get(`subscribe-now/currency-check-on-plan`, {
        params: data,
      })
      .then(async (response) => {
        if (
          response.data.planType &&
          response.data.planType !== data.planType
        ) {
          const planType = response.data.planType;
          replaceUrlParam("plan", planType);
          commit("cart/clear", null, { root: true });
          await dispatch("fastcheckout/fetchPlan", planType, { root: true });
        }

        if (response.data.currencyToBeUsed !== data.planCurrency) {
          const symbol = formatCurrency(response.data.currencyToBeUsed)[0];
          commit(
            "pricing/setUserCurrency",
            {
              code: response.data.currencyToBeUsed,
              symbol: symbol,
            },
            { root: true },
          );
          replaceUrlParam("curr", response.data.currencyToBeUsed);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async activateFreeTrial({ commit, dispatch }, data) {
    try {
      const response = await axios.post("payments/freetrial", { ...data });
    } catch (error) {
      console.error(error);
    }
    await dispatch("fetchGtmUserInfo");
    await dispatch("fetchWalletInfo", null, { root: true });
  },
  async updatePaymentMethod({ commit, dispatch, getters }) {
    await apiHandler(async () => {
      const paymentForm = getters.getPaymentForm;
      const stripeElementData = paymentForm.submitStripeElement();

      const stripeInstance = stripeElementData.stripeInstance;

      const res = await stripeInstance.createPaymentMethod({
        elements: stripeElementData.elements,
      });

      const paymentMethod = res.paymentMethod.id;

      await axios.post("payments/update-default-payment-method", {
        paymentMethod: paymentMethod,
      });
      commit("setIsPaymentMethodChanged", false);
      await dispatch("fetchDefaultPaymentMethod");
    });
  },
  async cancelMainSubscription() {
    return await axios.post("payments/cancel-subscription");
  },
  async fetchGtmUserInfo({ commit }) {
    const res = await axios.get("profile/info/gtm_user_info");
    commit("setGtmUserInfo", res.data);
  },
  /**
   *
   * reactivation code con be null, in that case, we can use
   * this function to reactivate sub also without code
   */
  async reactivateMainSubscription({ dispatch, rootGetters }) {
    preloader.fadeIn();
    const reactivateCodeKey = "reactivation-code";
    const user = rootGetters["getUser"];
    const reactivationCouponCode = getValueFromLocalStorage(reactivateCodeKey);
    return await axios
      .post("payments/reactivate-subscription", {
        code: reactivationCouponCode,
        user_id: user?.id,
      })
      .then((response) => {
        dispatch("fetchWalletInfo", null, { root: true });
        if (checkIfKeyExist(reactivateCodeKey)) {
          dispatch("deleteReactivationCode", null, { root: true });
        }
        preloader.fadeOut();
      })
      .catch((error) => {
        preloader.fadeOut();
        console.log(error.error);
        Message({
          showClose: true,
          message: "Coupon not valid",
          type: "error",
        });
        return false;
      });
  },
};
